import { default as _91slug_93KdgSv9fZ9vMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/[vertical]/[slug].vue?macro=true";
import { default as index1H8yBKo1bcMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/[vertical]/companies/index.vue?macro=true";
import { default as guidesvZ2urwPmmBMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/[vertical]/guides.vue?macro=true";
import { default as _91response_934iSd2VEKXkMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue?macro=true";
import { default as indexWqsvpoHaBLMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue?macro=true";
import { default as schedule_45callbackv8NiQkHcL2Meta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/[vertical]/schedule-callback.vue?macro=true";
import { default as about_45usk3x65hnYJpMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/about-us.vue?macro=true";
import { default as callbackNWwuXve0XQMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/authorization/callback.vue?macro=true";
import { default as mergeWIltjnvhAEMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/authorization/merge.vue?macro=true";
import { default as comprehensiveGRl1wjfn4SMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/car-insurance/comprehensive.vue?macro=true";
import { default as indexp84WAppy1eMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/car-insurance/index.vue?macro=true";
import { default as _91step_93mO2EqKLgLpMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue?macro=true";
import { default as detailsQjNCimCVkxMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/car-insurance/quote/form/details.vue?macro=true";
import { default as resultsz7qx06CynrMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/car-insurance/quote/form/results.vue?macro=true";
import { default as index5MgzyPeECXMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/car-loans/index.vue?macro=true";
import { default as indexqfk36eIp9hMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/careers/index.vue?macro=true";
import { default as collection_45noticeQGodULTzSeMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/collection-notice.vue?macro=true";
import { default as complaintspVXkLZXr4mMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/complaints.vue?macro=true";
import { default as contact_45usZGdtdWHyJRMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/contact-us.vue?macro=true";
import { default as _91slug_930vzve2MsWSMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/credit-cards/[slug].vue?macro=true";
import { default as indexLu5QiwDpO0Meta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/credit-cards/index.vue?macro=true";
import { default as _91slug_93BIaxOZ9om0Meta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/credit-cards/preview/[slug].vue?macro=true";
import { default as _91slug_936dPfVI0ZTMMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue?macro=true";
import { default as account_45settings9OwfyiGoLaMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/dashboard/account-settings.vue?macro=true";
import { default as index212RqLGqpGMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/dashboard/index.vue?macro=true";
import { default as claim_45offerlya28uyjiJMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue?macro=true";
import { default as indexSD8mtlBW6AMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/dashboard/onboarding/index.vue?macro=true";
import { default as indexqclmrl4hsHMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/dashboard/perks/index.vue?macro=true";
import { default as indexyRousaYLBNMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/dashboard/quotes/index.vue?macro=true";
import { default as indexOtZJ7B7Co1Meta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/dashboard/rewards/index.vue?macro=true";
import { default as additional_45detailsTvibnQr997Meta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/dashboard/vault/[[product]]/[vertical]/additional-details.vue?macro=true";
import { default as completionlL2KC7doJUMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/dashboard/vault/[[product]]/[vertical]/completion.vue?macro=true";
import { default as detailspt9ZuOTO45Meta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/dashboard/vault/[[product]]/details.vue?macro=true";
import { default as _91step_93bMDIYju4LQMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/energy/step/[step].vue?macro=true";
import { default as _91step_933obr5qnSRDMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/generic/step/[step].vue?macro=true";
import { default as _91step_93RhjBqxIF16Meta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/health-insurance/step/[step].vue?macro=true";
import { default as _91step_93D8fzxpNJ54Meta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/home-loan/step/[step].vue?macro=true";
import { default as _91step_93CCPVIteOz7Meta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/life-insurance/step/[step].vue?macro=true";
import { default as lic_45detailsxu5Dy5wlr7Meta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/dashboard/vault/[[product]]/lic-details.vue?macro=true";
import { default as rebateFYDvfkQnoJMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/dashboard/vault/[[product]]/rebate.vue?macro=true";
import { default as digital_45eicoSWgQDOWVgMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/energy/application/digital-eic.vue?macro=true";
import { default as errorLTCveQltu9Meta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/energy/application/error.vue?macro=true";
import { default as thank_45youI3IErSZrr1Meta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/energy/application/thank-you.vue?macro=true";
import { default as indexUZDMKHIaAfMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/energy/index.vue?macro=true";
import { default as _91step_93uLo2DstwryMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/energy/quote/form/[step].vue?macro=true";
import { default as details2c2mpH7muCMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/energy/quote/form/details.vue?macro=true";
import { default as thank_45youh8re4li0XLMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/energy/quote/thank-you.vue?macro=true";
import { default as _91slug_93ZwiErGyg7aMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue?macro=true";
import { default as indexw2Mj8xPN0FMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/expert-analysis/[category]/index.vue?macro=true";
import { default as _91author_93FVZ8vfZQObMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/expert-analysis/author/[author].vue?macro=true";
import { default as indexWPc5bEj475Meta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/expert-analysis/index.vue?macro=true";
import { default as forgot_45passwordTZvoMw5TZAMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/forgot-password.vue?macro=true";
import { default as _91step_93QSNrOFEe8tMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/application/[step].vue?macro=true";
import { default as expiredPwkC8AiJnyMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue?macro=true";
import { default as indexRgjWjx9cH7Meta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/application/payment/index.vue?macro=true";
import { default as indexWEIjD66yFFMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue?macro=true";
import { default as errorRtS9EKsSYaMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/error.vue?macro=true";
import { default as indexZsvLXgzg0sMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/index.vue?macro=true";
import { default as live_45chat_45bzYpGfbDDv8Meta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/live-chat-b.vue?macro=true";
import { default as live_45chatZ6AGfmsSuIMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/live-chat.vue?macro=true";
import { default as indexU8Fp2CfrrWMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/offers/index.vue?macro=true";
import { default as addF5UK6SdzKwMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/partners/add.vue?macro=true";
import { default as signupYvHIpNtORXMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/partners/signup.vue?macro=true";
import { default as post_45saleIxmyIrq8dDMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/post-sale.vue?macro=true";
import { default as indexyeZOICKJiRMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue?macro=true";
import { default as _91step_93NDcrpIQEaXMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue?macro=true";
import { default as detailsh9DnGeK0smMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/quote/form/details.vue?macro=true";
import { default as step0Xl6C23VFxmMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue?macro=true";
import { default as indexK2QtjNpXMcMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue?macro=true";
import { default as thank_45youWyEcOdt8heMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue?macro=true";
import { default as indexh7v5QC4b3qMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue?macro=true";
import { default as indexZVHCup0wyHMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue?macro=true";
import { default as index1gCkyPKAMQMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue?macro=true";
import { default as indexgjFfbRoHXPMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue?macro=true";
import { default as _91step_93ZKpZD4QEkQMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue?macro=true";
import { default as _91step_93mcC3SRPzW2Meta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue?macro=true";
import { default as confirmationSFGJWRm4NGMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue?macro=true";
import { default as details9ShI66BDNkMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue?macro=true";
import { default as index0BUK4AiDAdMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue?macro=true";
import { default as quotes_45resultsFJuprc7mkAMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue?macro=true";
import { default as thank_45youhBAzDECQvQMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue?macro=true";
import { default as _91type_93jARTEdNBO7Meta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/home-loans/calculators/[type].vue?macro=true";
import { default as indexUPffso1REQMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/home-loans/calculators/index.vue?macro=true";
import { default as indexs05APmXsUwMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/home-loans/index.vue?macro=true";
import { default as interest_45rateswWlQjh8tbgMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/home-loans/interest-rates.vue?macro=true";
import { default as _91step_93LoOje12VSfMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/home-loans/quote/form/[step].vue?macro=true";
import { default as detailsySPrUaJ2evMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/home-loans/quote/form/details.vue?macro=true";
import { default as thank_45you7c4Zp5YRZ4Meta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/home-loans/quote/thank-you.vue?macro=true";
import { default as index4mjELCq5XnMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/index.vue?macro=true";
import { default as confirmation1BSnxG7Bb6Meta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue?macro=true";
import { default as detailsHKLUOgMfLMMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue?macro=true";
import { default as indexEdwrOYo2S1Meta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue?macro=true";
import { default as indexW357Q76kH2Meta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/life-insurance/index.vue?macro=true";
import { default as add7QthF7CEgWMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/life-insurance/partners/add.vue?macro=true";
import { default as thank_45youOnBCD6h5H8Meta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue?macro=true";
import { default as indexAHFHPRA6C2Meta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue?macro=true";
import { default as successudYbBN6CkSMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue?macro=true";
import { default as _91step_93wn5tLu9OCIMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue?macro=true";
import { default as detailsc66QJ95v95Meta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/life-insurance/quote/form/details.vue?macro=true";
import { default as loginPj5hr4vdMlMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/login.vue?macro=true";
import { default as _91slug_93VJdHNYSV7UMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/media/[slug].vue?macro=true";
import { default as _91author_939eb29Co8ggMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/media/author/[author].vue?macro=true";
import { default as indexrVGF0GZmfpMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/media/index.vue?macro=true";
import { default as partnershipstgBFKhs692Meta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/partnerships.vue?macro=true";
import { default as index5tKhqbQgKUMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/personal-loans/index.vue?macro=true";
import { default as _91step_93nQbzQJhEi5Meta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue?macro=true";
import { default as detailsPphs0MoeypMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/personal-loans/quote/form/details.vue?macro=true";
import { default as thank_45youDL6cD1JlOMMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue?macro=true";
import { default as post_45sale_45thank_45youqbTBraosyZMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/post-sale-thank-you.vue?macro=true";
import { default as _91slug_93u3LYYngwsIMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/preview/[vertical]/[slug].vue?macro=true";
import { default as _91slug_93L1Yev1TGljMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue?macro=true";
import { default as _91slug_93Kqv22E9D9SMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/preview/media/[slug].vue?macro=true";
import { default as privacy_45policyS21hXlskVmMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/privacy-policy.vue?macro=true";
import { default as registerET6dbgLhvZMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/register.vue?macro=true";
import { default as reset_45password7IhmoQcoqoMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/reset-password.vue?macro=true";
import { default as terms_45of_45useS4UtE8sF0IMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/terms-of-use.vue?macro=true";
import { default as verifyNHqiCqVWNWMeta } from "/codebuild/output/src155224860/src/packages/nuxt/pages/verify.vue?macro=true";
export default [
  {
    name: "vertical-slug",
    path: "/:vertical()/:slug()",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/[vertical]/[slug].vue")
  },
  {
    name: "vertical-companies",
    path: "/:vertical()/companies",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  },
  {
    name: "vertical-guides",
    path: "/:vertical()/guides",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: schedule_45callbackv8NiQkHcL2Meta?.name,
    path: "/:vertical()/schedule-callback",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/[vertical]/schedule-callback.vue"),
    children: [
  {
    name: "vertical-schedule-callback-response",
    path: ":response()",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue")
  },
  {
    name: "vertical-schedule-callback",
    path: "",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue")
  }
]
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/about-us.vue")
  },
  {
    name: "authorization-callback",
    path: "/authorization/callback",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/authorization/callback.vue")
  },
  {
    name: "authorization-merge",
    path: "/authorization/merge",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/authorization/merge.vue")
  },
  {
    name: "car-insurance-comprehensive",
    path: "/car-insurance/comprehensive",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/car-insurance/comprehensive.vue")
  },
  {
    name: "car-insurance",
    path: "/car-insurance",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/car-insurance/index.vue")
  },
  {
    name: "car-insurance-quote-form-step",
    path: "/car-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue")
  },
  {
    name: "car-insurance-quote-form-details",
    path: "/car-insurance/quote/form/details",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/car-insurance/quote/form/details.vue")
  },
  {
    name: "car-insurance-quote-form-results",
    path: "/car-insurance/quote/form/results",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/car-insurance/quote/form/results.vue")
  },
  {
    name: "car-loans",
    path: "/car-loans",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/car-loans/index.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/careers/index.vue")
  },
  {
    name: "collection-notice",
    path: "/collection-notice",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/collection-notice.vue")
  },
  {
    name: "complaints",
    path: "/complaints",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/complaints.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/contact-us.vue")
  },
  {
    name: "credit-cards-slug",
    path: "/credit-cards/:slug()",
    meta: _91slug_930vzve2MsWSMeta || {},
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/credit-cards/[slug].vue")
  },
  {
    name: "credit-cards",
    path: "/credit-cards",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/credit-cards/index.vue")
  },
  {
    name: "credit-cards-preview-slug",
    path: "/credit-cards/preview/:slug()",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/credit-cards/preview/[slug].vue")
  },
  {
    name: "credit-cards-redirect-slug",
    path: "/credit-cards/redirect/:slug()",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue")
  },
  {
    name: "dashboard-account-settings",
    path: "/dashboard/account-settings",
    meta: account_45settings9OwfyiGoLaMeta || {},
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/dashboard/account-settings.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: index212RqLGqpGMeta || {},
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-offers-vertical-claim-offer",
    path: "/dashboard/offers/:vertical()/claim-offer",
    meta: claim_45offerlya28uyjiJMeta || {},
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue")
  },
  {
    name: "dashboard-onboarding",
    path: "/dashboard/onboarding",
    meta: indexSD8mtlBW6AMeta || {},
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/dashboard/onboarding/index.vue")
  },
  {
    name: "dashboard-perks",
    path: "/dashboard/perks",
    meta: indexqclmrl4hsHMeta || {},
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/dashboard/perks/index.vue")
  },
  {
    name: "dashboard-quotes",
    path: "/dashboard/quotes",
    meta: indexyRousaYLBNMeta || {},
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/dashboard/quotes/index.vue")
  },
  {
    name: "dashboard-rewards",
    path: "/dashboard/rewards",
    props: indexOtZJ7B7Co1Meta?.props ?? {"verificationNeeded":true},
    meta: indexOtZJ7B7Co1Meta || {},
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/dashboard/rewards/index.vue")
  },
  {
    name: "dashboard-vault-product-vertical-additional-details",
    path: "/dashboard/vault/:product?/:vertical()/additional-details",
    meta: additional_45detailsTvibnQr997Meta || {},
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/dashboard/vault/[[product]]/[vertical]/additional-details.vue")
  },
  {
    name: "dashboard-vault-product-vertical-completion",
    path: "/dashboard/vault/:product?/:vertical()/completion",
    meta: completionlL2KC7doJUMeta || {},
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/dashboard/vault/[[product]]/[vertical]/completion.vue")
  },
  {
    name: "dashboard-vault-product-details",
    path: "/dashboard/vault/:product?/details",
    meta: detailspt9ZuOTO45Meta || {},
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/dashboard/vault/[[product]]/details.vue")
  },
  {
    name: "dashboard-vault-product-form-energy-step-step",
    path: "/dashboard/vault/:product?/form/energy/step/:step()",
    meta: _91step_93bMDIYju4LQMeta || {},
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/energy/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-generic-step-step",
    path: "/dashboard/vault/:product?/form/generic/step/:step()",
    meta: _91step_933obr5qnSRDMeta || {},
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/generic/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-health-insurance-step-step",
    path: "/dashboard/vault/:product?/form/health-insurance/step/:step()",
    meta: _91step_93RhjBqxIF16Meta || {},
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/health-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-home-loan-step-step",
    path: "/dashboard/vault/:product?/form/home-loan/step/:step()",
    meta: _91step_93D8fzxpNJ54Meta || {},
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/home-loan/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-life-insurance-step-step",
    path: "/dashboard/vault/:product?/form/life-insurance/step/:step()",
    meta: _91step_93CCPVIteOz7Meta || {},
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/life-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-lic-details",
    path: "/dashboard/vault/:product?/lic-details",
    meta: lic_45detailsxu5Dy5wlr7Meta || {},
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/dashboard/vault/[[product]]/lic-details.vue")
  },
  {
    name: "dashboard-vault-product-rebate",
    path: "/dashboard/vault/:product?/rebate",
    meta: rebateFYDvfkQnoJMeta || {},
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/dashboard/vault/[[product]]/rebate.vue")
  },
  {
    name: "energy-application-digital-eic",
    path: "/energy/application/digital-eic",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/energy/application/digital-eic.vue")
  },
  {
    name: "energy-application-error",
    path: "/energy/application/error",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/energy/application/error.vue")
  },
  {
    name: "energy-application-thank-you",
    path: "/energy/application/thank-you",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/energy/application/thank-you.vue")
  },
  {
    name: "energy",
    path: "/energy",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/energy/index.vue")
  },
  {
    name: "energy-quote-form-step",
    path: "/energy/quote/form/:step()",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/energy/quote/form/[step].vue")
  },
  {
    name: "energy-quote-form-details",
    path: "/energy/quote/form/details",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/energy/quote/form/details.vue")
  },
  {
    name: "energy-quote-thank-you",
    path: "/energy/quote/thank-you",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/energy/quote/thank-you.vue")
  },
  {
    name: "expert-analysis-category-slug",
    path: "/expert-analysis/:category()/:slug()",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue")
  },
  {
    name: "expert-analysis-category",
    path: "/expert-analysis/:category()",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/expert-analysis/[category]/index.vue")
  },
  {
    name: "expert-analysis-author-author",
    path: "/expert-analysis/author/:author()",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/expert-analysis/author/[author].vue")
  },
  {
    name: "expert-analysis",
    path: "/expert-analysis",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/expert-analysis/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordTZvoMw5TZAMeta || {},
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/forgot-password.vue")
  },
  {
    name: "health-insurance-application-step",
    path: "/health-insurance/application/:step()",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/application/[step].vue")
  },
  {
    name: "health-insurance-application-payment-expired",
    path: "/health-insurance/application/payment/expired",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue")
  },
  {
    name: "health-insurance-application-payment",
    path: "/health-insurance/application/payment",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/application/payment/index.vue")
  },
  {
    name: "health-insurance-application-thankyou",
    path: "/health-insurance/application/thankyou",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue")
  },
  {
    name: "health-insurance-error",
    path: "/health-insurance/error",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/error.vue")
  },
  {
    name: "health-insurance",
    path: "/health-insurance",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/index.vue")
  },
  {
    name: "health-insurance-live-chat-b",
    path: "/health-insurance/live-chat-b",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/live-chat-b.vue")
  },
  {
    name: "health-insurance-live-chat",
    path: "/health-insurance/live-chat",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/live-chat.vue")
  },
  {
    name: "health-insurance-offers",
    path: "/health-insurance/offers",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/offers/index.vue")
  },
  {
    name: "health-insurance-partners-add",
    path: "/health-insurance/partners/add",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/partners/add.vue")
  },
  {
    name: "health-insurance-partners-signup",
    path: "/health-insurance/partners/signup",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/partners/signup.vue")
  },
  {
    name: "health-insurance-post-sale",
    path: "/health-insurance/post-sale",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/post-sale.vue")
  },
  {
    name: "health-insurance-quote-customer",
    path: "/health-insurance/quote/customer",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue")
  },
  {
    name: "health-insurance-quote-form-step",
    path: "/health-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue")
  },
  {
    name: "health-insurance-quote-form-details",
    path: "/health-insurance/quote/form/details",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-form-step0",
    path: "/health-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue")
  },
  {
    name: "health-insurance-quote-resume",
    path: "/health-insurance/quote/resume",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue")
  },
  {
    name: "health-insurance-quote-thank-you",
    path: "/health-insurance/quote/thank-you",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue")
  },
  {
    name: "health-insurance-quotes-results",
    path: "/health-insurance/quotes-results",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue")
  },
  {
    name: "health-insurance-rate-rise-calculator",
    path: "/health-insurance/rate-rise-calculator",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue")
  },
  {
    name: "health-insurance-students-offer-university-form",
    path: "/health-insurance/students-offer/:university()/form",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue")
  },
  {
    name: "health-insurance-students-offer-university-terms-conditions",
    path: "/health-insurance/students-offer/:university()/terms-conditions",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-step",
    path: "/health-insurance/visitors-visa-ovhc/:step()",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-application-step",
    path: "/health-insurance/visitors-visa-ovhc/application/:step()",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-confirmation",
    path: "/health-insurance/visitors-visa-ovhc/confirmation",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-details",
    path: "/health-insurance/visitors-visa-ovhc/details",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc",
    path: "/health-insurance/visitors-visa-ovhc",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-quotes-results",
    path: "/health-insurance/visitors-visa-ovhc/quotes-results",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-thank-you",
    path: "/health-insurance/visitors-visa-ovhc/thank-you",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue")
  },
  {
    name: "home-loans-calculators-type",
    path: "/home-loans/calculators/:type()",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/home-loans/calculators/[type].vue")
  },
  {
    name: "home-loans-calculators",
    path: "/home-loans/calculators",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/home-loans/calculators/index.vue")
  },
  {
    name: "home-loans",
    path: "/home-loans",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/home-loans/index.vue")
  },
  {
    name: "home-loans-interest-rates",
    path: "/home-loans/interest-rates",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/home-loans/interest-rates.vue")
  },
  {
    name: "home-loans-quote-form-step",
    path: "/home-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/home-loans/quote/form/[step].vue")
  },
  {
    name: "home-loans-quote-form-details",
    path: "/home-loans/quote/form/details",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/home-loans/quote/form/details.vue")
  },
  {
    name: "home-loans-quote-thank-you",
    path: "/home-loans/quote/thank-you",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/home-loans/quote/thank-you.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/index.vue")
  },
  {
    name: "life-insurance-income-protection-quote-confirmation",
    path: "/life-insurance/income-protection/quote/confirmation",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue")
  },
  {
    name: "life-insurance-income-protection-quote-details",
    path: "/life-insurance/income-protection/quote/details",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue")
  },
  {
    name: "life-insurance-income-protection-quote",
    path: "/life-insurance/income-protection/quote",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue")
  },
  {
    name: "life-insurance",
    path: "/life-insurance",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/life-insurance/index.vue")
  },
  {
    name: "life-insurance-partners-add",
    path: "/life-insurance/partners/add",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/life-insurance/partners/add.vue")
  },
  {
    name: "life-insurance-partners-thank-you",
    path: "/life-insurance/partners/thank-you",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue")
  },
  {
    name: "life-insurance-quote-customer",
    path: "/life-insurance/quote/customer",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue")
  },
  {
    name: "life-insurance-quote-customer-success",
    path: "/life-insurance/quote/customer/success",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue")
  },
  {
    name: "life-insurance-quote-form-step",
    path: "/life-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue")
  },
  {
    name: "life-insurance-quote-form-details",
    path: "/life-insurance/quote/form/details",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/life-insurance/quote/form/details.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginPj5hr4vdMlMeta || {},
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/login.vue")
  },
  {
    name: "media-slug",
    path: "/media/:slug()",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/media/[slug].vue")
  },
  {
    name: "media-author-author",
    path: "/media/author/:author()",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/media/author/[author].vue")
  },
  {
    name: "media",
    path: "/media",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/media/index.vue")
  },
  {
    name: "partnerships",
    path: "/partnerships",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/partnerships.vue")
  },
  {
    name: "personal-loans",
    path: "/personal-loans",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/personal-loans/index.vue")
  },
  {
    name: "personal-loans-quote-form-step",
    path: "/personal-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue")
  },
  {
    name: "personal-loans-quote-form-details",
    path: "/personal-loans/quote/form/details",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/personal-loans/quote/form/details.vue")
  },
  {
    name: "personal-loans-quote-thank-you",
    path: "/personal-loans/quote/thank-you",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue")
  },
  {
    name: "post-sale-thank-you",
    path: "/post-sale-thank-you",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/post-sale-thank-you.vue")
  },
  {
    name: "preview-vertical-slug",
    path: "/preview/:vertical()/:slug()",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/preview/[vertical]/[slug].vue")
  },
  {
    name: "preview-expert-analysis-vertical-slug",
    path: "/preview/expert-analysis/:vertical()/:slug()",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue")
  },
  {
    name: "preview-media-slug",
    path: "/preview/media/:slug()",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/preview/media/[slug].vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/privacy-policy.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerET6dbgLhvZMeta || {},
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/register.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45password7IhmoQcoqoMeta || {},
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/reset-password.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/terms-of-use.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/verify.vue")
  },
  {
    name: "Guides",
    path: "/credit-cards/guides",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "Companies",
    path: "/credit-cards/companies",
    component: () => import("/codebuild/output/src155224860/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  }
]